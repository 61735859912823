
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quizzes } from '../../models';
import QuizRow from './QuizRow.vue';
import QuizTile from './QuizTile.vue';

@Component({ components: { QuizTile, QuizRow } })
export default class QuizzesList extends Vue {
  @Prop({ type: Array as () => Quizzes, required: true }) readonly quizzes!: Quizzes;

  get viewModeGrid(): boolean {
    return this.$store.getters['user/viewModeGrid'];
  }
}
