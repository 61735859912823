
import { Vue, Component } from 'vue-property-decorator';
import { Quizzes, Quiz } from '../../models';
import { quizService } from '../../services';
import QuizzesList from './QuizzesList.vue';

@Component({ components: { QuizzesList } })
export default class QuizzesPanel extends Vue {
  draftQuizzes: Quizzes = [];
  activeQuizzes: Quizzes = [];
  closedQuizzes: Quizzes = [];

  loading = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async load() {
    // this.$bus.$emit('loading-indicator', true);
    this.loading = true;
    await Promise.all([
      await this.$bind('draftQuizzes', quizService.getDraftQuizzes(this.userId).orderBy('createdOn', 'desc')),
      await this.$bind('activeQuizzes', quizService.getActiveQuizzes(this.userId).orderBy('createdOn', 'desc')),
      await this.$bind('closedQuizzes', quizService.getClosedQuizzes(this.userId).orderBy('createdOn', 'desc')),
    ]);
    this.loading = false;
    // this.$bus.$emit('loading-indicator', false);
  }

  created() {
    this.load();
  }

  selectQuiz(quiz: Quiz) {
    this.$router.push(`/quiz/${quiz.id}`);
  }
}
