
import { Vue, Component } from 'vue-property-decorator';
import { User } from '@/models';
import { Game, Quiz } from '../models';
import { defaultGame } from '../defaults';
import { quizService } from '../services';
import QuizzesPanel from '../components/quiz/QuizzesPanel.vue';

@Component({ components: { QuizzesPanel } })
export default class QuizDashboardView extends Vue {
  showImportDialog = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  created() {
    this.$bus.$off('quiz-clone').$on('quiz-clone', this.onQuizClone);
    this.$bus.$off('quiz-export').$on('quiz-export', this.onQuizExport);
    this.$bus.$off('quiz-delete').$on('quiz-delete', this.onQuizDelete);
    this.$bus.$off('quiz-play').$on('quiz-play', this.onQuizPlay);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quizzes', '/quiz');
    document.title = 'AgileMate Quiz';
    this.$analytics.logEvent('quiz-dashboard');
  }

  // Event handlers
  async onQuizClone(quiz: Quiz) {
    try {
      await quizService.cloneQuiz(quiz, this.user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully cloned', 'success');
      this.$analytics.logEvent('quiz-clone', { quiz_id: quiz.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onQuizExport(quiz: Quiz) {
    try {
      await quizService.exportQuiz(quiz, this.user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully exported', 'success');
      this.$analytics.logEvent('quiz-export', { quiz_id: quiz.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onQuizDelete(quiz: Quiz) {
    try {
      await quizService.deleteQuiz(quiz.id);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully deleted', 'success');
      this.$analytics.logEvent('quiz-delete', { quiz_id: quiz.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onQuizPlay(quiz: Quiz) {
    try {
      //{ name: 'quiz-lobby', params: { id: quiz.id } }
      const game: Game = defaultGame(this.user);
      quizService.createGame(quiz.id, game);
      //await this.$router.push({ name: 'quiz-lobby', params: { id: quiz.id, gid: game.id } });
      this.$bus.$emit('snackbar-notify', 'Quiz game successfully started', 'success');
      this.$analytics.logEvent('quiz-play', { quiz_id: quiz.id, user_id: this.userId });
      await this.$router.push({ name: 'quiz-game-admin', params: { id: quiz.id, gid: game.id } });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
