








































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, Game, User } from '../../models';
import QuizScreen from './QuizScreen.vue';

@Component({ components: { QuizScreen } })
export default class GameSettings extends Vue {
  @Prop({ required: true })
  readonly quiz!: Quiz;

  @Prop({ required: true })
  readonly game!: Game;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
}
