











































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { $firebase } from '@/firebase';
import { sleep } from '@/utils';
import { Quiz, User, Participant, Participants, QuizPhase, Game, Ranking } from '../models';
import { quizService } from '../services';
import AdminQuestionStart from '../components/question/AdminQuestionStart.vue';
import AdminQuestionEnd from '../components/question/AdminQuestionEnd.vue';
import AdminQuizStart from '../components/quiz/AdminQuizStart.vue';
import AdminQuizEnd from '../components/quiz/AdminQuizEnd.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { AdminQuestionStart, AdminQuestionEnd, AdminQuizStart, AdminQuizEnd } })
export default class AdminQuizGameView extends Vue {
  async beforeRouteLeave(to, from, next) {
    //await this.disconnect();
    next();
  }

  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: String, required: true })
  readonly gid!: string;

  quiz: Quiz | null = null; // defaultQuiz(this.user);
  game: Game | null = null; //defaultGame(this.user);
  quizPhase = QuizPhase;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get isOwner(): boolean {
    return this.game ? this.userId === this.game.owner : false;
  }
  get isReadOnly(): boolean {
    return this.game ? this.game.status === 'Closed' : false;
  }
  get participants(): Participants {
    return this.game ? Object.values(this.game.participants) : [];
  }
  get participant(): Participant {
    const { id, username, email, avatar, lastLogon } = this.user;
    const participant: Participant = {
      id,
      username: username || 'Anonymous',
      email: email || 'anonymous@mail.com',
      avatar,
      lastLogon,
    };
    return participant;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('quiz', quizService.getQuizRef(id));
  }

  @Watch('gid', { immediate: true })
  async onGidChange(gid: string) {
    await this.$bind('game', quizService.getGameRef(this.id, gid));
    //await this.$bind('participants', quizService.getParticipantsRef(this.id, gid));
    this.$bus.$emit('loading-indicator', false);
    //await this.connectUser(this.participant);
    if (this.quiz) {
      document.title = this.quiz.title;
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quizzes', '/quiz');

    // Event listeners
    this.$bus.$off('quiz-settings-save').$on('quiz-settings-save', this.onSettingsSave);
    this.$bus.$off('quiz-settings-change').$on('quiz-settings-change', this.onSettingsChange);
    this.$bus.$off('quiz-start').$on('quiz-start', this.onQuizStart);
    this.$bus.$off('quiz-question-previous').$on('quiz-question-previous', this.onQuestionPrevious);
    this.$bus.$off('quiz-question-next').$on('quiz-question-next', this.onQuestionNext);
    this.$bus.$off('quiz-end').$on('quiz-end', this.onQuizEnd);
    this.$bus.$off('quiz-timer-timeout').$on('quiz-timer-timeout', this.onTimerTimeout);
  }

  async connectUser(participant: Participant) {
    if (this.isReadOnly || !this.quiz || !this.game) return;
    await quizService.createParticipant(this.quiz.id, this.game.id, participant);
  }
  async disconnectUser(participant: Participant) {
    if (this.isReadOnly || !this.quiz || !this.game) return;
    await quizService.deleteParticipant(this.quiz.id, this.game.id, participant.id);
  }
  async disconnect() {
    await this.disconnectUser(this.participant);
  }

  createRanking() {
    let ranking = [] as Ranking;
    if (!this.game) return ranking;
    for (const [userId, userStats] of Object.entries(this.game.stats)) {
      //ranking.push({ points: userStats.points, })
    }
    /*
    const gameResponses = this.game.responses;
    if (!gameResponses) return ranking;

    for (const [questionId, questionResponse] of Object.entries(gameResponses)) {
      for (const [userId, userResponse] of Object.entries(questionResponse)) {
        //       userResponse = { question, answer, startTime, endTime };
      }
    }
    */
    return ranking;
  }

  // Event handlers
  async onSettingsSave() {
    if (!this.quiz || !this.game) return;
    await quizService.updateGame(this.quiz.id, this.game.id, { settings: this.game.settings });
    this.$bus.$emit('snackbar-notify', 'Settings successfully saved', 'success');
  }
  async onSettingsChange(setting: string, value: boolean | string | number) {
    if (!this.quiz || !this.game) return;
    await quizService.updateGame(this.quiz.id, this.game.id, { [`settings.${setting}`]: value });
    //this.$bus.$emit('snackbar-notify', 'Settings successfully updated', 'success');
  }
  async onQuizStart() {
    if (!this.quiz || !this.game) return;
    await quizService.updateGame(this.quiz.id, this.game.id, { phase: QuizPhase.QuestionStart, currentQuestion: 1 });
    await quizService.updateQuiz(this.quiz.id, {
      ['stats.participants']: $firebase.firestore.FieldValue.increment(this.participants.length),
      ['stats.games']: $firebase.firestore.FieldValue.increment(1),
    });
    //await this.$router.push({ name: 'quiz-game', params: { id: this.quiz.id, gid: this.game.id } });
  }
  async onQuestionPrevious() {
    if (!this.quiz || !this.game) return;
    if (this.game.currentQuestion && this.game.currentQuestion > 0) {
      await quizService.updateGame(this.quiz.id, this.game.id, {
        ['currentQuestion']: $firebase.firestore.FieldValue.increment(-1),
      });
    }
  }
  async onQuestionNext() {
    if (!this.quiz || !this.game) return;
    if (this.game.currentQuestion) {
      if (this.game.currentQuestion < this.quiz.questions.length) {
        await quizService.updateGame(this.quiz.id, this.game.id, {
          phase: QuizPhase.QuestionStart,
          currentQuestion: $firebase.firestore.FieldValue.increment(1),
        });
      } else {
        await this.onQuizEnd();
      }
    } else if (!this.game.currentQuestion) {
      await quizService.updateGame(this.quiz.id, this.game.id, { currentQuestion: 1 });
    }
  }
  async onQuizEnd() {
    if (!this.quiz || !this.game) return;
    const ranking = this.createRanking();
    await quizService.updateGame(this.quiz.id, this.game.id, { phase: QuizPhase.QuizEnd, ranking });
    // TODO: Add statistics from game (totalQuestions, correctQuestions)
  }
  async onTimerTimeout() {
    if (
      !this.quiz ||
      !this.game ||
      !this.game.settings ||
      !this.game.responses ||
      !this.game.currentQuestion ||
      !this.game.participants
    )
      return;
    // Check if all players submitted their question responses!
    const question = this.quiz.questions[this.game.currentQuestion - 1];
    const questionId = question.id;
    const responsesCount = Object.keys(this.game.responses[questionId] || []).length;
    const participantsCount = Object.keys(this.game.participants).length;
    const allResponded = responsesCount === participantsCount;
    //console.log('Timer timeout', allResponded, this.game.responses, this.participants, this.game.settings);
    await sleep(1000);
    await quizService.updateGame(this.quiz.id, this.game.id, { phase: QuizPhase.QuestionEnd });
    if (this.game.settings.autoNextQuestion && allResponded) {
      await sleep(5000);
      this.onQuestionNext();
    }
  }

  onBeforeUnload() {
    this.disconnect();
  }
}
