




























































































































































































































































































































































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { getStatusColor } from '@/utils';
import { Quiz, QuizConfig, Question, QuestionType, User } from '../../models';
import {
  defaultQuizCategories,
  defaultQuestionTypes,
  defaultQuiz,
  defaultQuestion,
  QuestionTimerOptions,
} from '../../defaults';
import { quizService } from '../../services';

import ThemeSelector from '../ThemeSelector.vue';
import ImportDialog from '../ImportDialog.vue';
import TrueFalseQuestionEdit from '../question/TrueFalseQuestionEdit.vue';
import SingleChoiceQuestionEdit from '../question/SingleChoiceQuestionEdit.vue';
import MultipleChoiceQuestionEdit from '../question/MultipleChoiceQuestionEdit.vue';

@Component({
  components: {
    ThemeSelector,
    ImportDialog,
    TrueFalseQuestionEdit,
    SingleChoiceQuestionEdit,
    MultipleChoiceQuestionEdit,
  },
})
export default class QuizBuilder extends Vue {
  @PropSync('value', { type: Object as () => Quiz, required: true }) quiz!: Quiz;
  valid = false;
  step = 1;
  // quizConfig: QuizConfig = { categories: defaultQuizCategories };
  questionType: QuestionType | null = null;
  questionTypes = defaultQuestionTypes;
  questionTimerOptions = QuestionTimerOptions;
  questionDbDialog = false;
  quizCategories = defaultQuizCategories;
  selectedCategories: string[] = [];
  questions: Question[] = [];
  selectedQuestion: Question | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get statusColor(): string {
    return getStatusColor(this.quiz.status);
  }

  @Watch('quizConfig')
  onQuizConfigChanged(quizConfig) {
    this.$bus.$emit('loading-indicator', false);
    // if (quizConfig && quizConfig.questions && quizConfig.questions.length > 0) {
    //   this.questions = quizConfig.questions;
    // }
  }

  created() {
    this.$bus.$emit('title-change', 'Quizzes', '/quiz');
    this.$bind('quizConfig', quizService.getConfigRef());
  }

  createQuestion(questionType) {
    const question: Question = defaultQuestion({ title: 'Question', type: questionType });
    this.quiz.questions.push(question);
    this.$nextTick(() => {
      this.questionType = null;
    });
  }
  deleteQuestion(index) {
    if (this.quiz.questions.length > index) {
      this.quiz.questions.splice(index, 1);
      this.$bus.$emit('snackbar-notify', 'Question successfully deleted', 'success');
    }
  }
  resetQuiz() {
    this.quiz = defaultQuiz(this.user);
    this.$emit('change', this.quiz);
    this.$bus.$emit('snackbar-notify', 'Quiz reset', 'success');
  }
  saveQuiz() {
    this.$emit('save', this.quiz);
  }
  deleteQuiz() {
    this.$emit('delete', this.quiz);
  }
  setQuizStatus(status: string) {
    this.quiz.status = status;
    this.saveQuiz();
  }
  changeType(question) {
    console.log('Type changed', question.type);
  }
  changeCategory(categories) {
    if (categories.length > 0) {
      this.questions = this.questions.filter((q: Question) => q && q.category && categories.includes(q.category.title));
      // } else if (this.quizConfig) {
      //   this.questions = this.quizConfig.questions || [];
    }
  }
  addQuestion() {
    if (this.selectedQuestion) {
      this.quiz.questions.push(this.selectedQuestion);
      this.questions = this.questions.filter((q: Question) => q.title !== this.selectedQuestion!['title']);
      this.selectedQuestion = null;
    }
    //this.questionDbDialog = false;
  }

  async importQuiz(quiz: Quiz) {
    this.quiz = quiz;
    // this.quizId = null;
  }
}
