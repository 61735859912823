import { getId } from '@/utils';
import { Choice, Choices, Question, Questions, Quiz } from './models';

export function calculateChoiceWidth(value: number, total: number): string | null {
  if (total === 0) return null;
  return Math.round((value / total) * 100) + '%';
}

// Kahoot import
export function importKahootQuiz(destQuiz: Quiz, srcQuiz: any) {
  // Map quiz
  destQuiz.title = stripTags(srcQuiz.title);
  destQuiz.description = `${stripTags(srcQuiz.description)} Imported from Kahoot (https://create.kahoot.it/details/${
    srcQuiz.uuid
  })`;
  destQuiz.category = srcQuiz.audience;
  destQuiz.tags = srcQuiz.tags ? srcQuiz.tags.split(',') : [];
  destQuiz.image = srcQuiz.cover;
  destQuiz.video = null;
  destQuiz.questions = [] as Questions;

  const questions = srcQuiz.questions.filter((q) => q.type === 'quiz'); // Import only 'quiz'-type questions!
  //console.log('Questions for import', questions);
  questions.forEach((question) => {
    const choices: Choices = question['choices'].map((choice, cidx) => {
      return {
        sequence: cidx + 1,
        title: stripTags(choice.answer),
        correct: choice.correct,
      };
    });
    const newQuestion: Question = {
      id: getId(),
      title: stripTags(question.question),
      type: guessQuestionType(question),
      choices: choices,
      category: null,
      tags: [],
      image: question.image || null,
      video: question.video['fullUrl'] || null,
      time: question.time,
      points: question.points ? 1000 * question.pointsMultiplier : 0,
    };
    //if (question.image) importedQuestion.image = question.image;
    //if (question.video) importedQuestion.video = question.video.fullUrl;
    destQuiz.questions.push(newQuestion);
  });
}

function guessQuestionType(question): string {
  let type: string;
  const correctChoices = question.choices.filter((c) => c.correct).length;
  if (question.choices.length === 2 && ['True', 'False'].includes(question.choices[0].answer)) {
    type = 'TrueFalse';
  } else if (correctChoices > 1) {
    type = 'MultipleChoice';
  } else {
    type = 'SingleChoice';
  }
  return type;
}

// Quizizz import
export function importQuizizzQuiz(destQuiz: Quiz, srcQuiz: any) {
  // Map quiz
  destQuiz.title = stripTags(srcQuiz.info.name);
  destQuiz.description = `Imported from Quizizz (https://quizizz.com/admin/quiz/${srcQuiz._id})`;
  destQuiz.category = srcQuiz.info.subjects[0];
  destQuiz.tags = srcQuiz.info.topics;
  destQuiz.image = srcQuiz.info.image;
  destQuiz.video = null;
  destQuiz.questions = [] as Questions;

  // Map questions
  srcQuiz.info.questions.forEach((question) => {
    const newQuestion: Question = {
      id: getId(), //question._id,
      title: stripTags(question.structure.query.text),
      type: convertType(question.structure.kind),
      tags: question.topics,
      choices: [] as Choices,
      image: lookupMedia(question.structure.query.media, 'image'),
      video: lookupMedia(question.structure.query.media, 'video'),
      time: question.time,
      points: 1000, // Source value missing!
    };
    // Map choices
    question.structure.options.forEach((option, oidx) => {
      const newChoice: Choice = {
        sequence: oidx + 1,
        title: stripTags(option.text),
        correct: lookupCorrect(question.structure.answer, oidx),
        image: lookupMedia(option.media, 'image'),
        video: lookupMedia(option.media, 'video'),
      };
      newQuestion.choices.push(newChoice);
    });

    destQuiz.questions.push(newQuestion);
  });
  return destQuiz;
}

function convertType(type: string) {
  const typeMap = { MCQ: 'SingleChoice', MSQ: 'MultipleChoice' };
  return typeMap[type] || 'Undefined';
}

function lookupCorrect(answerIdx: number | number[], oidx: number) {
  if (!Array.isArray(answerIdx)) answerIdx = [answerIdx];
  return answerIdx.includes(oidx);
}

function lookupMedia(media, type = 'image') {
  if (!Array.isArray(media)) return null;
  const urls = media.filter((m) => m.type === type).map((m) => m.url);
  return urls[0] || null;
}

function stripTags(value: string) {
  return value.replace(/<\/?[^>]+(>|$)/g, '').replace('&nbsp;', '');
}

export function calculateScore(points: number, time: number, speed: number, streak: number = 0) {
  const speedWeight = 0.5; // 50% max
  const streakWeight = 0.1; // 10% fixed
  const correctnessBonus = points;
  const speedBonus = time ? Math.round((1 - speed / time) * points * speedWeight) : 0;
  const streakBonus = Math.round((correctnessBonus + speedBonus) * streak * streakWeight);
  const totalScore = correctnessBonus + speedBonus + streakBonus;
  return {
    totalScore,
    correctnessBonus,
    speedBonus,
    streakBonus,
  };
}

export default {
  calculateChoiceWidth,
  importKahootQuiz,
  importQuizizzQuiz,
  calculateScore,
};
