import { getId } from '@/utils';
import {
  QuestionType,
  Quiz,
  QuizSettings,
  QuizStats,
  QuizThemes,
  QuizType,
  QuizTypes,
  QuizTemplates,
  Question,
  Choices,
  Choice,
  Game,
  User,
  QuizPhase,
  GameSettings,
  Category,
  UserStatistics,
} from './models';

export const MaxQuestionLength = 256;
export const MaxAnswerLength = 128;
export const QuizTimerOptions = [
  0,
  30000,
  60000,
  120000,
  180000,
  240000,
  300000,
  360000,
  420000,
  480000,
  540000,
  600000,
  900000,
  1200000,
  1800000,
]; // Time in seconds
export const QuestionTimerOptions = [5000, 10000, 20000, 30000, 40000, 50000, 60000, 90000, 120000, 180000, 240000]; // Time in milliseconds
export const QuestionPointsOptions = [0, 1000, 2000];

export const defaultQuizCategories = [
  'Agile',
  'Art',
  'Computing',
  'Culture',
  'General',
  'Geography',
  'History',
  'Languages',
  'Mathematics',
  'Music',
  'Programming',
  'Science',
  'Security',
  'Social',
  'Technology',
  'Trivia',
  'Other',
];

export const defaultQuestionTime = 20000;
export const defaultQuestionPoints = 1000;

export const defaultQuestionTypes = {
  TrueFalse: { id: 'TrueFalse', title: 'True/False', icon: 'mdi-toggle-switch-off' },
  SingleChoice: { id: 'SingleChoice', title: 'Single Choice', icon: 'mdi-radiobox-marked' },
  MultipleChoice: { id: 'MultipleChoice', title: 'Multiple Choice', icon: 'mdi-checkbox-multiple-marked' },
};

export const defaultQuizType: QuizType = QuizTypes.Quiz;
export const defaultQuestionType: QuestionType = defaultQuestionTypes[0];
export const defaultTheme = 'CleanDark';

export const defaultTemplates: QuizTemplates = {
  Quiz: {
    id: 'Quiz',
    title: 'Quiz',
    description: 'Standard quiz (default)',
    icon: 'mdi-head-question',
    color: 'indigo',
    style: 'background: linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%);',
    questionTypes: ['TrueFalse', 'SingleChoice', 'MultipleChoice'],
  },
};

export const defaultThemes: QuizThemes = {
  Clean: {
    id: 'Clean',
    title: 'Clean',
    description: 'Clean theme (default)',
    colors: { text: '#000000', highlight: 'blue white--text', background: '#ffffff' },
  },
  CleanDark: {
    id: 'CleanDark',
    title: 'Clean Dark',
    description: 'Clean dark theme',
    colors: { text: '#ffffff', highlight: 'amber black--text', background: '#333333' },
  },
  Office: {
    id: 'Office',
    title: 'Office',
    description: 'Office theme',
    image:
      'https://images.pexels.com/photos/461077/pexels-photo-461077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'brown lighten-3 black--text', background: '#33333399' },
  },
  Team: {
    id: 'Team',
    title: 'Team',
    description: 'The Team collaboration theme',
    image:
      'https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'amber lighten-3 black--text', background: '#00000066' },
  },
  Matrix: {
    id: 'Matrix',
    title: 'Matrix',
    description: 'The Matrix theme',
    image:
      'https://images.pexels.com/photos/1089438/pexels-photo-1089438.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'green lighten-1 black--text', background: '#00000066' },
  },
  Code: {
    id: 'Code',
    title: 'Code',
    description: 'The Coders theme',
    image:
      'https://images.pexels.com/photos/97077/pexels-photo-97077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'deep-purple lighten-2 black--text', background: '#ffffff99' },
  },
  Coffee: {
    id: 'Coffee',
    title: 'Coffee',
    description: 'The Coffee dream theme',
    image:
      'https://images.pexels.com/photos/982612/pexels-photo-982612.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'brown lighten-1 white--text', background: '#00000066' },
  },
  Forest: {
    id: 'Forest',
    title: 'Forest',
    description: 'The Forest theme',
    image:
      'https://images.pexels.com/photos/1125776/pexels-photo-1125776.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'lime darken-4 white--text', background: '#cccccccc' },
  },
  Beach: {
    id: 'Beach',
    title: 'Beach',
    description: 'The Beach theme',
    image:
      'https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'cyan lighten-4 black--text', background: '#ffffffcc' },
  },
  Trendy: {
    id: 'Trendy',
    title: 'Trendy',
    description: 'The Trendy theme',
    image:
      'https://images.pexels.com/photos/590041/pexels-photo-590041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'orange black--text', background: '#ffffff99' },
  },
  Digital: {
    id: 'Digital',
    title: 'Digital',
    description: 'The Digital theme',
    image:
      'https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'blue-grey darken-3 white--text', background: '#ffffffcc' },
  },
  Zen: {
    id: 'Zen',
    title: 'Zen',
    description: 'The Zen theme',
    image:
      'https://images.pexels.com/photos/312839/pexels-photo-312839.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'blue-grey darken-4 white--text', background: '#ffffffcc' },
  },
};

export const defaultQuizStats: QuizStats = {
  games: 0,
  participants: 0,
  totalQuestions: 0,
  correctQuestions: 0,
  favorites: [],
};

export const defaultQuizSettings: QuizSettings = {
  timeRemaining: null,
  isPublic: false,
};

export const defaultQuiz = (user: User): Quiz => ({
  id: getId(),
  title: 'Quiz',
  description: null,
  type: defaultQuizType,
  status: 'Draft',
  owner: user.id,
  createdBy: user,
  createdOn: null,
  theme: defaultTheme,
  settings: defaultQuizSettings,
  stats: defaultQuizStats,
  questions: [],
});

export const defaultGameSettings: GameSettings = {
  enableLeaderboard: true,
  enableMusic: false,
  enableSounds: false,
  enableTimer: true,
  enableStreakBonus: true,
  shuffleAnswers: false,
  shuffleQuestions: false,
  //autoJoinQuiz: true,
  autoNextQuestion: false,
};

export const defaultGame = (user: User): Game => ({
  id: getId(),
  status: 'Draft',
  owner: user.id,
  createdBy: user,
  createdOn: Date.now(),
  settings: defaultGameSettings,
  phase: QuizPhase.QuizStart,
  currentQuestion: null,
  participants: {},
  responses: {},
  ranking: [],
  stats: {},
});

export const defaultChoices = function (count: number = 4, titles: string[] = [], corrects: boolean[] = []): Choices {
  const choices: Choices = [];
  for (let i = 0; i < count; i++) {
    const sequence = i + 1;
    const title = titles[i] || 'Answer choice ' + sequence;
    const correct = corrects[i] || false;
    const choice: Choice = { sequence, title, image: null, video: null, correct };
    choices.push(choice);
  }
  return choices;
  //return [{ title: 'Answer choice 1' }, { title: 'Answer choice 2' }];
};

export const defaultQuestion = ({
  title = 'Question',
  type = defaultQuestionType.id,
  category = null,
  time = defaultQuestionTime,
  points = defaultQuestionPoints,
}: {
  title?: string;
  type?: string;
  category?: Category | null;
  time?: number | null;
  points?: number | null;
}): Question => ({
  id: getId(),
  title,
  type,
  choices: [],
  category,
  tags: [],
  image: null,
  video: null,
  time,
  points,
});

export const defaultStats = (user: User): UserStatistics => ({
  user,
  points: 0,
  speed: 0,
  streak: 0,
  accuracy: 0,
  correctQuestions: 0,
  totalQuestions: 0,
});

export const defaultColors = () => [
  'blue',
  'cyan',
  'amber',
  'pink',
  'purple',
  'deep-purple',
  'teal',
  'blue-grey',
  'brown',
  'green',
];

export const kahootIcons = () => [
  'mdi-triangle',
  'mdi-rhombus',
  'mdi-circle',
  'mdi-square',
  'mdi-star',
  'mdi-hexagon',
  'mdi-cards-club',
  'mdi-cards-diamond',
  'mdi-cards-heart',
  'mdi-cards-spade',
];

export const alphabetIcons = () => [
  'mdi-alpha-a-box',
  'mdi-alpha-b-box',
  'mdi-alpha-c-box',
  'mdi-alpha-d-box',
  'mdi-alpha-e-box',
  'mdi-alpha-f-box',
  'mdi-alpha-g-box',
  'mdi-alpha-h-box',
  'mdi-alpha-i-box',
  'mdi-alpha-j-box',
];

export const numericIcons = () => [
  'mdi-numeric-1-box',
  'mdi-numeric-2-box',
  'mdi-numeric-3-box',
  'mdi-numeric-4-box',
  'mdi-numeric-5-box',
  'mdi-numeric-7-box',
  'mdi-numeric-8-box',
  'mdi-numeric-9-box',
];

export const defaultIcons = alphabetIcons;

export const defaultQuizCountdown = 5;
export const defaultQuestionCountdown = 5;
