
import { Vue, Component, Watch, ModelSync } from 'vue-property-decorator';
import { func } from '@/firebase';
import { Quiz } from '../models';
import { importKahootQuiz, importQuizizzQuiz } from '../utils';

@Component
export default class ImportDialog extends Vue {
  @ModelSync('value', 'input', { required: true })
  quiz!: Quiz;

  @Watch('importing')
  onChange(value) {
    if (!value) this.showDialog = false; // Close dialog when import is finished!
  }

  valid = false;
  importing = false;
  showDialog = false;
  quizSource: string | null = null;
  quizId: string | null = null;
  quizSources = ['Quizizz', 'Kahoot'];

  async importQuiz() {
    if (!this.quiz || !this.quizId) return;
    this.importing = true;

    try {
      const fetchQuiz = func.httpsCallable('fetchQuiz');
      const result = await fetchQuiz({ quizSource: this.quizSource, quizId: this.quizId });
      const quizData = result.data;
      console.log('[quizData]', quizData);
      try {
        this.quizSource === 'Quizizz' ? importQuizizzQuiz(this.quiz, quizData) : importKahootQuiz(this.quiz, quizData);
        this.$bus.$emit('snackbar-notify', 'Quiz successfully imported', 'success');
      } catch (error) {
        this.$bus.$emit('snackbar-notify', `Quiz could not be imported (${error})`, 'error');
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', `Quiz could not be fetched (${error})`, 'error');
    }

    this.importing = false;
    this.quizId = null;
    this.$emit('quiz-import', this.quiz);
  }

  closeDialog() {
    this.quizId = null;
    this.showDialog = false;
  }
}
