










import { Vue, Component } from 'vue-property-decorator';
import { Quiz, User } from '../models';
import { defaultQuiz } from '../defaults';
import { quizService } from '../services';
import QuizBuilder from '../components/quiz/QuizBuilder.vue';

@Component({ components: { QuizBuilder } })
export default class QuizCreateView extends Vue {
  quiz: Quiz = defaultQuiz(this.user);

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quizzes', '/quiz');
    document.title = 'AgileMate New Quiz';
  }

  async saveQuiz(quiz: Quiz) {
    try {
      quiz.owner = this.user.id;
      quiz.createdBy = this.user;
      quiz.createdOn = Date.now();
      await quizService.createQuiz(quiz);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully created', 'success');
      this.$router.push({ name: 'quiz-dashboard' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
