


























































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Quiz, Game, User } from '../../models';
import { defaultGame } from '../../defaults';
import { quizService } from '../../services';
import { formatDate, dateFromNow, dateToNow, getStatusColor } from '@/utils';

@Component
export default class QuizCard extends Vue {
  @Prop({ required: true }) quiz!: Quiz;

  formatDate = formatDate;
  dateFromNow = dateFromNow;
  dateToNow = dateToNow;
  getStatusColor = getStatusColor;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get isOwner(): boolean {
    return this.quiz.owner === this.userId;
  }
  get quizAccuracy(): number {
    return this.quiz.stats.totalQuestions === 0
      ? 0
      : (this.quiz.stats.correctQuestions / this.quiz.stats.totalQuestions) * 100;
  }

  async cloneQuiz(quiz: Quiz) {
    try {
      await quizService.cloneQuiz(quiz, this.user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteQuiz(quiz: Quiz) {
    try {
      await quizService.deleteQuiz(quiz.id);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async playQuiz(quiz: Quiz) {
    //{ name: 'quiz-lobby', params: { id: quiz.id } }
    const game: Game = defaultGame(this.user);
    quizService.createGame(quiz.id, game);
    //await this.$router.push({ name: 'quiz-lobby', params: { id: quiz.id, gid: game.id } });
    await this.$router.push({ name: 'quiz-game-admin', params: { id: quiz.id, gid: game.id } });
  }
}
