
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { defaultThemes } from '../../defaults';
import { Quiz, QuizTheme, Games, Participants, User } from '../../models';
import { quizService } from '../../services';
import QuizActions from './QuizActions.vue';

@Component({ components: { QuizActions } })
export default class QuizTile extends Vue {
  @Prop({ type: Object as () => Quiz, required: true }) readonly quiz!: Quiz;

  games: Games = [];
  participants: Participants = [];
  formatDate = formatDate;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get quizDuration(): number {
    return this.quiz.settings.timeRemaining || 0;
  }
  get participantsCount(): number {
    let participantsCount = 0;
    this.games.forEach((game) => (participantsCount += Object.keys(game.participants).length));
    return participantsCount;
  }
  get quizCompletion(): number {
    return 0;
  }
  get theme(): QuizTheme {
    let theme = defaultThemes[this.quiz.theme];
    theme.image = this.quiz.background || theme.image;
    return theme;
  }

  created() {
    this.$bind('games', quizService.getGamesRef(this.quiz.id));
    //this.$bind('participants', quizService.getParticipantsRef(this.quiz.id));
  }
}
