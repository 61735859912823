
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Quiz, User } from '../models';
import { quizService } from '../services';

@Component
export default class QuizCloneView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Quiz | null = null;

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('quiz', quizService.getQuizRef(id));
  }

  @Watch('quiz')
  async onQuizChange(quiz: Quiz) {
    if (quiz) {
      if (quiz.title) document.title = quiz.title;
      this.$bus.$emit('loading-indicator', false);
      await this.cloneQuiz(quiz);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Quizzes', '/quiz');
  }

  async cloneQuiz(quiz: Quiz) {
    try {
      const user = this.$store.getters['user/user'] as User;
      await quizService.cloneQuiz(quiz, user);
      this.$bus.$emit('snackbar-notify', 'Quiz successfully cloned', 'success');
      this.$router.push({ name: 'quiz-dashboard' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
